/*balance*/
.user-body{
    background-color: var(--two-color);
    color: var(--two-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    min-width: 100%;
    min-height: 100vh;
    z-index: 0;
    overflow: auto;
}

.balance-container-box{
    position: relative;
    /* top: -5vh; */
    height: 50vh;
    /* overflow: auto; */
}
@media (max-width: 480px){
    .balance-container-box{
        /* top: -5vh; */
        height: 100%;
    }
    .packets-list-block{

        margin: 0;
        border-radius: 0;
        padding: 0 10px;
    }
}

@media (min-width: 480px) and (max-width: 759px){
    .balance-container-box{
        /* top: -5vh; */
        height: 100%;
    }
}

.balance-container{
    position: relative;
    background-color: var(--one-color);
    padding: 40px;
    margin-top: 5%;
    overflow: hidden;
}

.user-profile-form-btn img{
    max-width: 40px;
    margin: 1px 10px;
}

.user-tarif-buttons {
    margin: 5px;
    background-color: var(--three-color);
    color: var(--four-color);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-weight: normal;
    border-radius: 5px;
}

.user-tarif-buttons.hovered {
  background-color: var(--four-color);
  color: var(--three-color);
  opacity: 1;
}

.user-tarif-buttons.clicked {
  background-color: var(--four-color);
  color: var(--three-color);
}

.user-tarif-transfer{
    font-size: 20px;
    margin-top: 40px;
}

.user-tarif-transfer input{
    outline: 0;
    border-radius: 5px;
    border: 0;
    padding: 10px;
    color: var(--four-color);
    width: 40%;
    margin-left:10px;
}

.bank-transfer-btn{
    border-radius: 10px;
    background-color: var(--four-color);
    color: var(--three-color);
    border: 0;
    padding: 12.5px;
    font-size: 15px;
    width: 50%;
}

.user-profile-form-btn{
    padding: 0;
    margin: 0 5px;
    border: 0;
    border-radius: 10px;
    background-color: var(--two-color);
    margin-bottom: 10px;
    width: 50%;
}

/*Profile*/
.user-profile-body{
    position: absolute;
    top: 0%;
    min-height: 100vh;
    /* max-height: 100vh; */
    width: 100vw;
    overflow: auto;
    margin: 0;
}

.user-profile-body-in{
    /* position: absolute; */
    width: 100%;
    height: auto;
    /* top: 9vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--two-color); */
    padding-bottom: 10px;
    position: relative;
    overflow: hidden;
}

.user-profile-block{
    background-color: var(--one-color);
    color: var(--two-color);
    font-size: 20px;
    padding: 30px;
    overflow: hidden;
    /* position: var(); */
}

.user-menu-profile-block{
    background-color: var(--one-color);
}
.user-profile-block-container{
    width: 100%;
    margin: 0;
    padding: 0;
}

/*Column half colapsed*/
.user-info-input{
    border: 0;
    outline: none;
    color: var(--one-color);
    background-color: var(--two-color);
    font-size: 15px;
    padding: 10px;
    border-radius: 10px;
}


.user-profile-save-btn{
    background-color: var(--two-color);
    color: var(--one-color);
    padding: 10px;
    border: 0;
    border-radius: 10px;
    font-size: 15px;
}

.block-to-flex-column{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}