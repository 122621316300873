.footer{
    background-color: var(--one-color);
    padding: 20px 0;
    color:  var(--three-color);
    width: 100%;
}


.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}
  
.column {
    text-align: center;
}
.column .contactIcons{
    float: left;
    width: 30px;
}


p img{
    float: left;
    width: 30px;
}

a{
    color:  var(--three-color);
    text-decoration: none;
}
a:hover{
    opacity: 0.8;
}
  
.footer-btns{
    border: 0;
    color:  var(--three-color);
    font-size: 20px;
    cursor: pointer;
    transition: transform 0.3s, color 0.3s;
    border-radius: 5px;
    padding: 10px;
}


.partner{
    font-size: 20px;
}

.partner-img{
    width: 100%;
}

.downloads{
    max-width: 20px;
    float: right;
}


@media (min-width: 1200px) {
    .container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
    }
}
  
@media (min-width: 768px) and (max-width: 1199px) {
    .container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
}

@media (max-width: 767px) {
    .container {
        display: grid;
        grid-template-columns: 1fr;
    }
}