.plus-btn{
    padding: 0;
    border: 0;
    background-color:transparent; 
    position: relative;
    margin: 10px;
}
.plus-btn img{
    width: 30px;
    height: auto;
    margin: 5px;
}

.inputList input, .inputList button{
    border-radius: 5px;
    border: 0;
    padding: 10px;
    margin: 5px;
}

.inputList input{
    color: var(--four-color);
    outline-width: 0;
}

.inputList button{
    background-color: var(--four-color);
    color: var(--three-color);
}

.region-input{
    width: 30px;
}
.decoder-input{
    width: 70px;
}

.decoder-form{
    width: 50%;
    background-color: var(--one-color);
    padding: 1.5em 1.5em;
    border-radius: 30px;
    color: var(--two-color);
    height: auto;
    display: grid;
}


.info-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
}

.info-col span{
    margin: 5px;
}


.tarif-choose{
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.btn-block{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tarif-choose-btn{
    width: 100%;
    background-color: var(--three-color);
    color: var(--four-color);
    border: 0;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tarif-choose-btn span{
    margin: 5px;
}

.tarrifSubmit{
    border: 0;
    border-radius: 10px;
    padding: 10px;
    background-color: var(--two-color);
    color: var(--one-color);
}

.decoder-back-btn{
    position: absolute;
    background-color: transparent;
    border: 0;
    color: var(--two-color);
    display: flex;
    align-items: center;
    top: 10px;
    right: 10px;
}

.decoder-back-btn img{
    width: 30px;
    margin: 5px;
}