.modal-window{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    z-index: 1000;
}

.error-block{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    /* top: -10%;
    left: 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.error-massage{
    text-align: center;
    background-color: var(--three-color);
    border-radius: 5px;
    border: 0;
    padding: 30px;
    color: var(--one-color);
}
.error-btn{
    border-radius: 5px;
    border: 0;
    background-color: var(--one-color);
    color: var(--three-color);
    padding: 10px;
    font-size: 15px;
}