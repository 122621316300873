.packets-list-block{
    max-width: 100vw;
    background-color: var(--three-color);
    color: var(--one-color);
    position: relative;
    margin: 10px 0;
    border-radius: 10px;
    padding: 0 10px;   
    display: block;
}

.packets-list-card{
    max-width: 100%;
    margin: 10px 0;
    border: 1px solid var(--one-color);
    border-radius: 5px;
    padding: 5px;
}

.packets-list-card-header{
    display: flex;
    justify-content: space-between;
}
.packets-list-apply{
    border: 0;
    border-radius: 5px;
    padding: 5px;
    margin: 0;
    font-size: 15px;
    background-color: var(--four-color);
    color: var(--three-color);
    box-shadow: 1px 1px 1px 1px #00000080;    
}

td{
    padding: 5px;
    border: 1px solid #A2D5F2;
    border-radius: 3px;
}
.td-btn{
    padding: 0;
    border: 0;
} 

.td-btn input{
    margin: 0;
    padding: 10px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 0;
}
.packets-list-card-table{
    width: 100%;
    padding: 0;
}
.clickable{
    border-radius: 5px;
    -webkit-user-select: none;
    user-select: none;
}
.modal-show-btn{
    background-color: var(--one-color);
    color: var(--three-color);
    border-radius: 5px;
    padding: 5px;
    font-size: 15px;
    border: 0;
    box-shadow: 1px 1px 1px 1px #00000080;  
}
/* 
.td-btn-clicked{
    background-color: #07689F;
    color: #A2D5F2;
} */
