.packages-tariff-grid-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
.packages-tariff{
    text-align: center;
    padding: 0 10vw;
}   

.packages-tariff-card{
    /* width: 300px; */
    /* height: 200px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding-top: 20px; */
    padding: 20px 5px 5px 5px;
    background-color: var(--one-color);
    border: 2px solid  var(--four-color);
    border-radius: 10px;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2), -10px -10px 5px rgba(0, 0, 0, 0.2);
}
.packages-tariff-card ul{
    text-align: start;
}

.packages-tariff-card .tariff-btn{
    background-color: var(--four-color);
    /* border: 1px solid var(--three-color); */
    padding: 10px;
    border: 0;
    color: var(--three-color);
    border-radius: 5px;
    box-shadow: 0 2.5px 2.5px rgba(0, 0, 0, 0.3);
}

@media (max-width:500px) {
    .packages-tariff-grid-container{
        grid-template-columns: repeat(1, 1fr);
    }
}
@media (max-width:890px) {
    .devices{
        flex-direction: column;
    }
}


.devices{
    display: flex; align-items: center;
}

.shopB{
    width: auto;
    height: 120px;
}

.our-service-for-subs{
    text-align: center;
}