/* Общие стили для скроллбара */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: var(--two-color);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: var(--one-color);
  border-radius: 2px;
}
img{
  margin: 0;
}

button{
  cursor: pointer;
  margin: 5px;
}

button, a, img{
  -webkit-user-select: none; 
  -moz-user-select: none;
  -ms-user-select: none; 
  user-select: none; 
}

button:hover{
  opacity: 0.8;
}

.main-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:var(--one-color);
  padding: 10px;
  height: 10vh;
}

.main-logo {
  max-width: 50px;
  height: auto;
  margin: 5px;
  float: left;
}
.shopLink{
  display: flex;
  align-items: center;
}
@media (max-width:890px) {
  .shopE{
    width: 200px;
    height: auto;
  }
  .shop{
    width: 75px;
    height: auto;
    margin-right: 10px;
  }
}
@media (min-width:890px) {
  .shopE{
    width: 500px;
    height: auto;
  }
  .shop{
    width: 150px;
    height: auto;
    margin-right: 40px;
  }
}

.language-choise{
  width: auto;
  margin: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #A2D5F2;
}

.language-choise img{
  max-width: 30px;
}

.info{
  max-width: 20px;
} 

.with-icon{
  padding: 0;
  background-color: var(--one-color);
  margin: 5px;
  display: flex;
  align-self: center;
  justify-self: center;
  border: 0;
  color: #FAFAFA;
}

.menu {
  display: flex;
  gap: 10px;
}

.main-title{
  position: relative;
  font-size: 40px;
  margin: 0;
  display: flex;
  align-items: center;
  color: var(--two-color);
  -webkit-user-select: none;
  user-select: none;
}  

.title-btn{
  background: transparent;
  border: 0;
  margin: 0;
}

.main-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.main-modal-content {
  top: 100px;
  right: 10px;
  position: absolute;
  background: var(--one-color);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-size: 10px;
}


@media (max-width: 450px){
  .hidden{
    display: block;
  }
  .showen{
    display: none;
  }
  
  .title-btn{
    margin: 0 -7px;
  }
  .main-logo {
    max-width: 50px;
  }

  .main-modal-content button{
    font-size: 10px;
  }
  .main-title{
    font-size: 20px;
  }
}
@media (min-width: 451px){
  .hidden{
    display: none;
  }
  .showen{
    display: block;
  }
}

@media (min-width: 768px){
  .header-btns{
    font-size: 25px;
  }
  .main-logo {
    max-width: 50px;
  }

  .info{
    max-width: 40px;
  }
  .main-modal-content button{
    font-size: 20px;
  }
}

.main-nav-btn{
  font-size: 20px;
  border: 0;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--two-color);
  color: var(--one-color);
  opacity: 1;
  margin: 2px;
}

.main-nav-btn img{
  max-width: 25px;
  padding: 0;
}

.videos{
  margin: 20px;
}


/* styles.css */
.accordion {
  position: absolute;
  z-index: 2;
  top: 100px;
  width: 200px;
  margin: auto;
  max-height: 250px; 
  overflow-y: scroll;
}

.accordion-item {
  background-color: var(--three-color);
  text-align: center;
}

.accordion-title {
  background-color: var(--three-color);
  color: var(--four-color);
  padding: 10px;
  border: 0;
  max-width: auto;
}

.accordion-content {
  padding: 10px;
}


.accordion::-webkit-scrollbar-thumb {
  background: var(--four-color);
  border-radius: 2px;
}

