.log{
    width:376px;
    background-color: var(--one-color);
    padding: 1.5em 1.5em;
    border-radius: 30px;
    color:  var(--two-color);
    height: auto;
}
.for-log{
    display: flex;
    align-items: center;
    height: 100vh;
}

.form{
    background-color: var(--two-color);
    border: 0;
    border-radius: 10px;
    height: 40px;
    width: 95%;
    padding: 0 10px ;
    color: var(--one-color);
    margin-top: 20px;
    outline: 0;
}

::placeholder {
    color: var(--one-color);
}

@media (max-width: 480px){
    .log{
        width: 100%;
        height: 100vh;
        margin: 0;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logs{
        width: 95%;
    }

    .reCAPT{
        width: 40px;
    }
    .for-log{
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }
}

.log-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px; 
}

.grid-log{
    border: 0;
    border-radius: 10px;
    padding: 10px 0;
}

.register-route-btn{
    background-color:var(--two-color);
    color: var(--one-color);
}

.login-accept-btn{
    background-color:var(--three-color);
    color: var(--four-color);
}


.error-message {
    color: var(--four-color);
    font-size: 12px;
    margin: 0 0 10px 0;
    padding: 0;
    margin-bottom: 10px;
}

.pass-change-btn{
    background-color: var(--one-color);
    color: var(--two-color);
    border: 0;
    border-radius: 10px;
    margin: 0;
}

.login-decline-btn{
    position: absolute;
    top: 10px;
    left: 10px;
    background-color:transparent;
    border: 0;
    color: var(--one-color);
    display: flex;
    align-items: center;
    font-size: 20px;
}

.login-decline-btn img{
    width: 40px;
    margin: 10px;
}

.modul-animated {
    font-size: 16px; 
    padding: 10px 20px; 
    animation: resizeButton 5s infinite alternate; 
  }
  
@keyframes resizeButton {
  0% {
    font-size: 16px; 
    padding: 10px 20px; 
  }
  50% {
    font-size: 20px;
    padding: 15px 25px;
  }
  100% {
    font-size: 16px; 
    padding: 10px 20px; 
  }
}
