.user-navbar {
  background-color:var(--one-color);
  display: flex; 
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.left-align{
  display: flex;
  align-items: center;
}

.user-logo {
  width: 45px;
  height: auto;
  margin: 10px;
  float: left;
}

.user-title{
  position: relative;
  margin: 5px;
  display: flex;
  align-items: center;
  color: var(--two-color);
  font-size:40px;
}

.user-nav-btns{
  font-size: 20px;
  margin: 0 5px;
  border: 0;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--two-color);
  color: var(--one-color);
  opacity: 1;
}


.logout{
  display: flex;
  justify-self: end;
}

.user-btn-menu{
  display: none;
}

.user-btn-menu-img{
  width: 30px;
  height: auto;
  margin: 5px;
}

.user-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  min-height: 100vh;
  max-height: auto;
  background-color: var(--one-color);
}

.user-modal-content {
  display: flex;
  flex-direction: column;
  background-color: transparent;  
  border-radius: 5px;
  font-size: 10px;
}

@media (max-width: 375px){
  .user-btn-menu{
    display: block;
    position: relative;
    border: 0;
    background-color: transparent;
  }
  .user-nav-btns{
    display: none;
  }
}

.user-nav-menu-btns{
  font-size: 20px;
  margin: 0 5px;
  border: 0;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--three-color);
  color: var(--four-color);
  opacity: 1;
  border: 0;
}