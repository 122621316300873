@page {
    size: A4;
    margin: 20mm;
  }
  .invoice-table{
      width: 100%;
      text-align: left;
  }
  .invoice-table-row{
      border-bottom: 1px solid #000;
      margin-bottom: 5px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
  }
  .invoice-container {
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 10mm;
    box-sizing: border-box;
  }
  .invoice-header, .invoice-footer {
    text-align: center;
    margin-bottom: 10mm;
  }
  .invoice-header .invoice-logo {
    width: 60%;
    margin-bottom: 10mm;
  }
  .bank-details {
    width: 100%;
    margin-bottom: 10mm;
  }
  .bank-details div {
    margin-bottom: 5mm;
  }
  .invoice-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10mm;
  }
  .invoice-info div {
    flex: 1;
  }
  .signature {
    text-align: left;
    margin-top: 20mm;
  }

  .modal-for-invoice{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    z-index: 1000;
}