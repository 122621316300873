.user-country{
    position: relative;
}

.country-list{
    display: block;
    position: absolute;
    top: 100%;
    border-radius: 5px;
    background-color: var(--two-color);
    max-height: 100px; 
    overflow-y: scroll;
    padding: 5px;
}
.country-list ul{
    list-style: none;
}      


.country-list-item{
    height: 25px;
    max-width: 200px;
    font-size: 16px;
    cursor: pointer;
    padding: 5px 0; 
    text-align: center;  
    margin: 5px 0;
    color: var(--one-color);
    /* text-decoration: none; */
    
} 