body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 
@media (max-width: 480px) {
  .pc{
    display: none;
  }
  .tablet{
    display: block;
  }
  .phone{
    display: flex;
  }
}

@media (min-width: 480px) and (max-width: 759px) {
  .pc{
    display: none;
  }
  .tablet{
    display: block;
  }
  .phone{
    display: none;
  }
}
@media (min-width: 759px) {
  .pc{
    display: flex;
  }
  .tablet{
    display: none;
  }
  .phone{
    display: none;
  }
} */

:root {
  --one-color:  #07689F; 
  /*#333A73 */
  --two-color: #A2D5F2;
  /* #FBA834 */
  --three-color: #FAFAFA;
  /*  #387ADF */
  --four-color: #FF7E67;
  /* #50C4ED  */
}