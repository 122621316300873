.main{
    background-color: var(--two-color);
    position: relative;
    height: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: auto;
}

.in{
    width: 85%;
    margin-top: 2em;
    margin-bottom:  2em;
    background-color: var(--one-color);
    padding: 1.5em 1.5em;
    border-radius: 10px;
    color: var(--two-color);
}
.main-pages-titles{
    color: var(--four-color);
    text-align: center;
}

.pointed{
    color: var(--four-color);
}

.map{
    width: 100%;
    margin-bottom: 10px;
    position: relative;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
}

.grid-item {
  padding: 16px;
  text-align: center;
}   

.canvas{
   position: relative; 
   width: 100%;
}

canvas {
  position: relative;
  width: 100%;
  height: 400px;
}

.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
.overlay p{
    width: 100%;
    text-align: center;
}

.overlay a{
    color: var(--one-color);
}

@media (min-width: 768px){
    .overlay img {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .overlay img{
        width: 100%;
    }
  .grid-container {
    grid-template-columns: 1fr;
  }
  .content-container{
    display: grid;
    grid-template-columns: 1fr;
  }
}
@media (min-width: 768px){
    .content-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

}

@media (max-width: 412px){
    .in{
        width: 100%;
        margin: 0;
        border-radius: 0;
    }
}
.content-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    gap: 20px;
}
  
.content-column {
    padding: 20px;
    text-align: start;
}
