/*Containers*/
@media (min-width: 760px) {
    .balance-container{
        border-radius: 20px;
    }
    .user-menu-profile-block{
        padding: 30px;
        min-width: 80%;
        max-width: 80%;
        height: auto;
        border-radius: 20px;
    }
    .user-profile-block{
        width: 80%;
        border-radius: 20px;
    }
    .user-profile-block-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
    .user-tarif-buttons {
        width: 21%;
    }
}

@media (max-width: 480px){
    .user-profile-block{
        width: 100%;
        border-radius: 0;
        display: flex;
        align-items: center;
    }
    .user-profile-block-container{
        position: relative;
        top: 50px;
    }
    .columnize{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .user-profile-body-in{
        top: 0;
    }
    .user-profile-body{
        height: 100vh;
    } 
    .user-profile-block button, .user-profile-block input{
        font-size: 20px;
    }
    .user-profile-block-container{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    .user-tarif-buttons {
        width: 95%;
    }    

}

@media (min-width: 480px) and (max-width: 759px){
    .balance-container{
        border-radius: 20px;
    }
    .user-profile-block{
        width: 80%;
        border-radius: 20px;
    }
    .user-profile-body-in{
        margin-top: 9vh;
    }
    .user-profile-block-container{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    .user-tarif-buttons {
        width: 47%;
    }
}


/*Decoder*/
@media (max-width: 699px){
    .decoder-form{
        width: 100%;
        height: 100vh;
        margin: 0;
        border-radius: 0; 
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
    .tarrifSubmit{
        width: 50%;
    }
   
}

@media (min-width: 700px){
    .decoder-form{
        grid-template-columns: 1fr 2fr;
    }
}
